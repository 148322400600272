import RouterHolder from '@/components/router-holder'
import * as ConstantRouter from '@/constant/router'

const route = {
  path: 'competition',
  name: ConstantRouter.DASHBOARD_COMPETITION,
  component: RouterHolder,
  redirect: '/admin/competition',
  children: [
    {
      path: '',
      component: () =>
        import(
          /* webpackChunkName: "dashboard-competition" */ '@/dashboard/competition'
        )
    },
    {
      path: 'add',
      name: ConstantRouter.DASHBOARD_COMPETITION_ADD,
      component: () =>
        import(
          /* webpackChunkName: "dashboard-competition-add" */ '@/dashboard/competition/add'
        )
    },
    {
      path: 'update/:id',
      name: ConstantRouter.DASHBOARD_COMPETITION_UPDATE,
      component: () =>
        import(
          /* webpackChunkName: "dashboard-competition-add" */ '@/dashboard/competition/add'
        )
    },
    {
      path: 'detail/:id',
      name: ConstantRouter.DASHBOARD_COMPETITION_DETAIL,
      component: () =>
        import(
          /* webpackChunkName: "dashboard-competition-detail" */ '@/dashboard/competition/detail'
        )
    },
    {
      path: 'track/:id',
      name: ConstantRouter.DASHBOARD_COMPETITION_TRACK,
      component: () =>
        import(
          /* webpackChunkName: "dashboard-competition-track" */ '@/dashboard/competition/track'
        )
    },
    {
      path: 'schedule/:id',
      name: ConstantRouter.DASHBOARD_COMPETITION_SCHEDULE,
      component: () =>
        import(
          /* webpackChunkName: "dashboard-competition-schedule" */ '@/dashboard/competition/schedule'
        )
    },
    {
      path: 'guide/:jsId/:id?',
      name: ConstantRouter.DASHBOARD_COMPETITION_GUIDE,
      component: () =>
        import(
          /* webpackChunkName: "dashboard-competition-guide" */ '@/dashboard/competition/guide'
        )
    }
  ]
}

export default route
