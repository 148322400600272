import { RouteConfig } from 'vue-router'
import Dashboard from '@/dashboard/index.vue'
import Competitor from '@/competitor/index.vue'
import Judge from '@/judge/index.vue'
import Views from '@/views/index.vue'
import Common from '@/common/index.vue'
import MiniArticle from '@/mini/article/index.vue'
import WebArticle from '@/mini/web/index.vue'

import dashboardRoutes from './dashboard'
import competitorRoutes from './competitor'
import judgeRoutes from './judge'
import viewRoutes from './view'

import dashboardLoginRoutes from './dashboard/login'
import competitorRegisterRoutes from './competitor/register'
import competitorLoginRoutes from './competitor/login'
import judgeLoginRoutes from './judge/login'

import * as ConstantRouter from '@/constant/router'

const routes: Array<RouteConfig> = [
  dashboardLoginRoutes,
  competitorRegisterRoutes,
  competitorLoginRoutes,
  judgeLoginRoutes,
  {
    path: '/common',
    component: Common,
    redirect: '/common/password-reset',
    children: [
      {
        path: 'password-reset',
        name: ConstantRouter.COMMON_PASSWORD_RESET,
        component: () =>
          import(
            /* webpackChunkName: "common-password-reset" */ '@/common/password-reset/index.vue'
          )
      }
    ]
  },
  {
    path: '/admin',
    name: ConstantRouter.DASHBOARD,
    component: Dashboard,
    redirect: {
      name: dashboardRoutes[0].name
    },
    children: dashboardRoutes
  },
  {
    path: '/competitor/:jsId?',
    name: ConstantRouter.COMPETITOR,
    component: Competitor,
    redirect: {
      name: competitorRoutes[0].name
    },
    children: competitorRoutes
  },
  {
    path: '/judge/:jsId?',
    name: ConstantRouter.JUDGE,
    component: Judge,
    redirect: {
      name: judgeRoutes[0].name
    },
    children: judgeRoutes
  },
  {
    path: '/mini/:id',
    component: MiniArticle
  },
  {
    path: '/web/:id',
    component: WebArticle
  },
  {
    path: '/',
    component: Views,
    name: ConstantRouter.VIEW,
    redirect: {
      name: viewRoutes[0].name
    },
    children: viewRoutes
  }
]

export default routes
