<template>
  <div class="page-event">
    <Banner></Banner>
    <Video :detail="detail"></Video>
    <Process v-if="detail" :detail="detail"></Process>
    <News></News>
    <Works></Works>
    <Interview></Interview>
    <Contact></Contact>
  </div>
</template>

<script>
import Banner from './coms/banner'
import Video from './coms/video'
import Process from './coms/process'
import News from './coms/news'
import Works from './coms/works'
import Interview from './coms/interview'
import Contact from './coms/contact'

export default {
  data() {
    return {
      eventList: [],
      eventTotal: 0,
      eventCurrent: 1,
      eventPageSize: 10,
      loading: true,
      jsId: this.$route.params.jsId,
      detail: null
    }
  },
  components: {
    Banner,
    Video,
    Process,
    News,
    Works,
    Interview,
    Contact
  },
  async mounted() {
    if (!this.jsId) {
      this.jsId = await this.fetchList()
    }
    this.fetchDetail()
    this.fetchPopupConfig()
  },
  methods: {
    async fetchList(pageIndex = 1) {
      const [err, res] = await this.$serve.jsRecord.getPageList({
        data: { pageIndex }
      })
      if (err) return
      this.eventList = res.records
      this.eventTotal = res.total
      this.eventCurrent = res.pageIndex
      this.eventPageSize = res.pageSize
      return this.eventList[0].id
    },
    async fetchDetail() {
      this.loading = true
      const [err, res] = await this.$serve.jsRecord.info({
        queryId: this.jsId
      })
      this.loading = false
      if (err) return
      this.detail = res
    },
    async fetchPopupConfig() {
      this.loading = true
      const [err, res] = await this.$serve.jsTanchuang.infoByJs({
        queryId: this.jsId
      })
      this.loading = false
      if (err) return
      if (res && res.title) {
        this.$alert(
          `<p style="padding: 16px; line-height: 40px; min-height: 100px; text-align: center; font-size: 28px">${res.title}</p>`,
          {
            dangerouslyUseHTMLString: true,
            showConfirmButton: false
          }
        )
      }
    }
  }
}
</script>
