import router from '@/router'
import * as ConstantRouter from '@/constant/router'

export function getFileName(path) {
  return path.substring(path.lastIndexOf('/') + 1)
}

export function saveFile(path, name) {
  const elink = document.createElement('a')
  if (name === undefined) {
    name = getFileName(path)
  }
  elink.download = name
  elink.style.display = 'none'
  elink.href = path
  elink.target = '_blank'
  document.body.appendChild(elink)
  elink.click()
  URL.revokeObjectURL(elink.href)
  document.body.removeChild(elink)
}

/**
 * 删除对象中属性值为null、undefined和''的属性
 */
export function getValidParam(obj, empty = false) {
  const param = {}
  if (obj === null || obj === undefined || obj === '') return param
  for (var key in obj) {
    if (Object.prototype.toString.call(obj) === 'Object') {
      param[key] = getValidParam(obj[key])
    } else if (
      obj[key] !== null &&
      obj[key] !== undefined &&
      (empty ? true : obj[key] !== '')
    ) {
      param[key] = obj[key]
    }
  }

  return param
}

export function randomString(len = 32) {
  const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  const maxPos = chars.length
  let str = ''
  for (let i = 0; i < len; i += 1) {
    str += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return str
}

export function treeToMap(tree) {
  const fn = (tree) => {
    return tree.reduce((pre, next) => {
      return {
        ...pre,
        [next.key]: next,
        ...(next.children && next.children.length ? fn(next.children) : null)
      }
    }, {})
  }
  return fn(tree)
}

export function newWindowOpen(data) {
  const routeData = router.resolve(data)
  window.open(routeData.href, '_blank')
}

export function isView() {
  const matched = router.history.current.matched
  if (!matched || !matched.length) return false
  return matched.some((item) => {
    return item.name === ConstantRouter.VIEW
  })
}

export function isCompetitor() {
  const matched = router.history.current.matched
  if (!matched || !matched.length) return false
  return matched.some((item) => {
    return item.name === ConstantRouter.COMPETITOR
  })
}

export function exportFile(file, name) {
  const xlsx =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
  const blob = new Blob([file], { type: xlsx })
  const a = document.createElement('a')
  a.download = name
  a.href = window.URL.createObjectURL(blob)
  a.click()
  a.remove()
}
