const apis = [
  {
    // 重置密码
    fn: 'resetPassword',
    method: 'POST',
    url: '/api/jsEmail/resetPassword',
    hideLoading: true
  },
  {
    // 发送找回密码验证码
    fn: 'sendEmail',
    method: 'POST',
    url: '/api/jsEmail/sendEmail ',
    hideLoading: true
  }
]

export default apis
