export default {
  ['title']: 'Reset Password',
  ['email']: 'E-mail',
  ['captcha']: 'Captcha',
  ['captcha-btn']: 'Fetch Captcha',
  ['password']: 'Password',
  ['confirm-password']: 'Confirm Password',
  ['placeholder-input']: 'Please Input',
  ['add-submit-text']: 'Save',
  ['add-cancel-text']: 'Cancel'
}
