export default {
  ['title']: 'SUBMISSION',
  
  ['menu-team']: 'TEAM',
  ['menu-team-add']: 'ADD TEAM',
  ['menu-team-update']: 'UPDATE TEAM',
  ['menu-team-detail']: 'TEAM DETAIL',

  ['menu-entries']: 'ENTRIES',
  ['menu-entries-add']: 'ADD ENTRIES',
  ['menu-entries-update']: 'UPDATE ENTRIES',
  ['menu-entries-detail']: 'ENTRIES DETAIL',

  ['password-reset']: 'Password Reset',
  ['logout']: 'Logout',
}
