import RouterHolder from '@/components/router-holder'
import * as ConstantRouter from '@/constant/router'

const route = {
  path: 'news',
  name: ConstantRouter.DASHBOARD_NEWS,
  component: RouterHolder,
  redirect: '/admin/news',
  children: [
    {
      path: '',
      component: () => import(/* webpackChunkName: "dashboard-news" */ '@/dashboard/news')
    },
    {
      path: 'add',
      name: ConstantRouter.DASHBOARD_NEWS_ADD,
      component: () =>
        import(/* webpackChunkName: "dashboard-news-add" */ '@/dashboard/news/add'),
    },
    {
      path: 'update/:id',
      name: ConstantRouter.DASHBOARD_NEWS_UPDATE,
      component: () =>
        import(/* webpackChunkName: "dashboard-news-add" */ '@/dashboard/news/add'),
    },
    {
      path: 'detail/:id',
      name: ConstantRouter.DASHBOARD_NEWS_DETAIL,
      component: () =>
        import(/* webpackChunkName: "dashboard-news-detail" */ '@/dashboard/news/detail'),
    }
  ]
}

export default route
