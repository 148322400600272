<template>
  <div class="layout-dashboard">
    <layout-basic
      title="后台系统"
      :user="dashboardInfo"
      :menu="menu"
      siderWidth="200px"
    ></layout-basic>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LayoutBasic from '@/layout/layout-basic'
import menu from '@/config/menu-dashboard'

export default {
  components: {
    LayoutBasic
  },
  data() {
    return {
      menu
    }
  },
  computed: {
    ...mapState({
      dashboardInfo(state) {
        return state.dashboardInfo
      }
    })
  },
  mounted() {
    if (!this.dashboardInfo) this.$store.dispatch('fetchDashboardInfo')
  }
}
</script>
