import * as ConstantRouter from '@/constant/router'
import * as ConstantLabel from '@/constant/label'

/**
 * 菜单列表
 * hide 隐藏当前菜单
 * hideChildrenInMenu 隐藏所有子菜单
 * key 路由唯一值
 * name 路由名称
 */
const menu = [
  {
    key: ConstantRouter.COMPETITOR_MEMBERS,
    name: ConstantLabel.COMPETITOR_MEMBERS,
    icon: 'el-icon-folder-opened',
    hideChildrenInMenu: true,
    children: [
      {
        key: ConstantRouter.COMPETITOR_MEMBERS_ADD,
        name: ConstantLabel.COMPETITOR_MEMBERS_ADD
      },
      {
        key: ConstantRouter.COMPETITOR_MEMBERS_UPDATE,
        name: ConstantLabel.COMPETITOR_MEMBERS_UPDATE
      },
      {
        key: ConstantRouter.COMPETITOR_MEMBERS_DETAIL,
        name: ConstantLabel.COMPETITOR_MEMBERS_DETAIL
      }
    ]
  },
  {
    key: ConstantRouter.COMPETITOR_WORKS,
    name: ConstantLabel.COMPETITOR_WORKS,
    icon: 'el-icon-folder-opened',
    hideChildrenInMenu: true,
    children: [
      {
        key: ConstantRouter.COMPETITOR_WORKS_ADD,
        name: ConstantLabel.COMPETITOR_WORKS_ADD
      },
      {
        key: ConstantRouter.COMPETITOR_WORKS_UPDATE,
        name: ConstantLabel.COMPETITOR_WORKS_UPDATE
      },
      {
        key: ConstantRouter.COMPETITOR_WORKS_DETAIL,
        name: ConstantLabel.COMPETITOR_WORKS_DETAIL
      }
    ]
  },
  {
    key: ConstantRouter.COMPETITOR_PASSWORD_RESET,
    name: ConstantLabel.COMPETITOR_PASSWORD_RESET,
    hide: true
  }
]

export default menu
