export default {
  ['submit-text']: 'Search',
  ['reset-text']: 'Reset',
  ['add-text']: 'Add Team Members',
  ['name']: 'Name',
  ['phone']: 'Phone Number',
  ['role']: 'Role',
  ['role-leader']: 'Team Leader',
  ['role-member']: 'Member',
  ['role-instructor']: 'Instructor',
  ['competition']: 'Competition',
  ['idNum']: 'Passport Number',
  ['address']: 'Address',
  ['unit']: 'Unit / School',
  ['operation']: 'Edit',
  ['operation-detail']: 'Details',
  ['operation-edit']: 'Edit',
  ['operation-delete']: 'Delete',

  ['confirm-delete-title']: 'Confirm Delete',
  ['message-delete-success']: 'Delete Success',

  ['placeholder-input']: 'Please Input',
  ['placeholder-select']: 'Please Select',

  ['add-tip-1']:
    'If participate in groups, each group should not exceed 3 players.',
  ['add-tip-2']:
    'Teams with instructors can add up to 2 instructors (in no particular order).',
  ['add-tip-3']:
    'When registering as a group, only one member needs to register and log in, and fill in the team member‘s information. There is no need to repeat the registration and delivery.',

  ['add-submit-text']: 'Save',
  ['add-cancel-text']: 'Cancel',

  ['message-add-success']: 'Add Success',
  ['message-edit-success']: 'Edit Suceess',
  ['message-member-delete-success']: 'Delete Success'
}
