import i18n from '@/i18n'

export const HOME = '主页'

/**
 * competitor
 */
export const COMPETITOR_WORKS = i18n.t('layout.menu-entries')
export const COMPETITOR_WORKS_ADD = i18n.t('layout.menu-entries-add')
export const COMPETITOR_WORKS_UPDATE = i18n.t('layout.menu-entries-update')
export const COMPETITOR_WORKS_DETAIL = i18n.t('layout.menu-entries-detail')

export const COMPETITOR_MEMBERS = i18n.t('layout.menu-team')
export const COMPETITOR_MEMBERS_ADD = i18n.t('layout.menu-team-add')
export const COMPETITOR_MEMBERS_UPDATE = i18n.t('layout.menu-team-update')
export const COMPETITOR_MEMBERS_DETAIL = i18n.t('layout.menu-team-detail')

export const COMPETITOR_PASSWORD_RESET = '重置密码'

/**
 * judge
 */
export const JUDGE_REVIEW = '评审管理'
export const JUDGE_REVIEW_ADD = '评审添加'
export const JUDGE_REVIEW_DETAIL = '评审详情'

/**
 * dashboard
 */
export const DASHBOARD_BANNERS = '轮播图管理'
export const DASHBOARD_BANNERS_ADD = '轮播图添加'
export const DASHBOARD_BANNERS_UPDATE = '轮播图修改'
export const DASHBOARD_BANNERS_DETAIL = '轮播图详情'

export const DASHBOARD_NEWS = '新闻管理'
export const DASHBOARD_NEWS_ADD = '新闻添加'
export const DASHBOARD_NEWS_DETAIL = '新闻详情'

export const DASHBOARD_COMPETITION = '竞赛管理'
export const DASHBOARD_COMPETITION_ADD = '竞赛添加'
export const DASHBOARD_COMPETITION_UPDATE = '竞赛修改'
export const DASHBOARD_COMPETITION_TRACK = '赛道管理'
export const DASHBOARD_COMPETITION_SCHEDULE = '日程管理'
export const DASHBOARD_COMPETITION_GUIDE = '指南管理'
export const DASHBOARD_COMPETITION_DETAIL = '竞赛详情'

export const DASHBOARD_WORKS = '作品管理'
export const DASHBOARD_WORKS_ADD = '作品添加'
export const DASHBOARD_WORKS_DETAIL = '作品详情'

export const DASHBOARD_EXPERTS = '专家管理'
export const DASHBOARD_EXPERTS_ADD = '专家添加'
export const DASHBOARD_EXPERTS_UPDATE = '专家修改'
export const DASHBOARD_EXPERTS_DETAIL = '专家详情'


export const DASHBOARD_POPUP = '弹窗管理'
export const DASHBOARD_POPUP_ADD = '弹窗添加'
export const DASHBOARD_POPUP_UPDATE = '弹窗修改'
export const DASHBOARD_POPUP_DETAIL = '弹窗详情'

export const DASHBOARD_MINI_BANNERS = '小程序轮播图管理'
export const DASHBOARD_MINI_BANNERS_ADD = '小程序轮播图添加'
export const DASHBOARD_MINI_BANNERS_UPDATE = '小程序轮播图修改'
export const DASHBOARD_MINI_BANNERS_DETAIL = '小程序轮播图详情'

export const DASHBOARD_MINI_ARTICLE = '小程序文章管理'
export const DASHBOARD_MINI_ARTICLE_ADD = '小程序文章添加'
export const DASHBOARD_MINI_ARTICLE_UPDATE = '小程序文章修改'
export const DASHBOARD_MINI_ARTICLE_DETAIL = '小程序文章详情'
