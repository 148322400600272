<template>
  <div class="com-sider-view">
    <div class="com-sider-view__logo">
      <p v-if="menuCollapse">
        <!-- <router-link to="/">
          <i class="el-icon-picture" style="font-size: 18px"></i>
        </router-link> -->
        <i class="el-icon-picture" style="font-size: 18px"></i>
      </p>
      <p v-else class="com-sider-view__logo-text">
        <!-- <router-link to="/">报名系统</router-link> -->
        {{ title }}
      </p>
    </div>
    <el-menu
      :default-active="menuActive"
      :collapse="screen['screen-xs'] ? false : menuCollapse"
      :collapse-transition="false"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      class="com-sider-view__menu"
    >
      <tree-menu :data="menu" :menu-click="menuClick"></tree-menu>
    </el-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TreeMenu from '@/components/tree-menu'
import { treeToMap } from '@/utils/tools'
import queryString from 'query-string'

export default {
  components: {
    TreeMenu
  },
  props: {
    title: String,
    menu: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState({
      menuCollapse(state) {
        return state.menuCollapse
      },
      screen(state) {
        return state.screen
      }
    }),
    menuMap() {
      return treeToMap(this.menu)
    },
    menuActive() {
      if (this.$route === '/') {
        return ''
      }
      const matched = this.$route.matched
      /**
       * 在子菜单隐藏或者菜单隐藏的时候需要筛选菜单匹配
       */
      const menuMatched = matched.filter((menu) => {
        if (
          menu.parent &&
          menu.parent.name &&
          this.menuMap[menu.parent.name]?.hideChildrenInMenu
        ) {
          return false
        }
        if (this.menuMap[menu.name]?.hide) {
          return false
        }
        return true
      })
      const active = menuMatched[menuMatched.length - 1].name
      return Object.keys(this.$route.query).length
        ? active + '?' + queryString.stringify(this.$route.query)
        : active
    }
  },
  methods: {
    menuClick(menuItem) {
      this.$router.push({
        ...(this.$route.name === menuItem.key ? {} : { name: menuItem.key }),
        query: menuItem.query
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.com-sider-view__logo {
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;
  // color: rgba(0, 0, 0, 0.85);
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #ffd04b;
  a {
    color: #ffd04b;
  }
  img {
    width: 24px;
  }
}
.com-sider-view__logo-text {
  position: relative;
}
.com-sider-view__menu {
  border-right: none;
}
</style>
