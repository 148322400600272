import * as ConstantRouter from '@/constant/router'
import * as ConstantLabel from '@/constant/label'

/**
 * 菜单列表
 * hide 隐藏当前菜单
 * hideChildrenInMenu 隐藏所有子菜单
 * key 路由唯一值
 * name 路由名称
 */
const menu = [
  {
    key: ConstantRouter.DASHBOARD_COMPETITION,
    name: ConstantLabel.DASHBOARD_COMPETITION,
    icon: 'el-icon-folder-opened',
    hideChildrenInMenu: true,
    children: [
      {
        key: ConstantRouter.DASHBOARD_COMPETITION_ADD,
        name: ConstantLabel.DASHBOARD_COMPETITION_ADD
      },
      {
        key: ConstantRouter.DASHBOARD_COMPETITION_UPDATE,
        name: ConstantLabel.DASHBOARD_COMPETITION_UPDATE
      },
      {
        key: ConstantRouter.DASHBOARD_COMPETITION_DETAIL,
        name: ConstantLabel.DASHBOARD_COMPETITION_DETAIL
      },
      {
        key: ConstantRouter.DASHBOARD_COMPETITION_TRACK,
        name: ConstantLabel.DASHBOARD_COMPETITION_TRACK
      },
      {
        key: ConstantRouter.DASHBOARD_COMPETITION_SCHEDULE,
        name: ConstantLabel.DASHBOARD_COMPETITION_SCHEDULE
      },
      {
        key: ConstantRouter.DASHBOARD_COMPETITION_GUIDE,
        name: ConstantLabel.DASHBOARD_COMPETITION_GUIDE
      }
    ]
  },
  {
    key: ConstantRouter.DASHBOARD_BANNERS,
    name: ConstantLabel.DASHBOARD_BANNERS,
    icon: 'el-icon-folder-opened',
    hideChildrenInMenu: true,
    children: [
      {
        key: ConstantRouter.DASHBOARD_BANNERS_ADD,
        name: ConstantLabel.DASHBOARD_BANNERS_ADD
      },
      {
        key: ConstantRouter.DASHBOARD_BANNERS_UPDATE,
        name: ConstantLabel.DASHBOARD_BANNERS_UPDATE
      },
      {
        key: ConstantRouter.DASHBOARD_BANNERS_DETAIL,
        name: ConstantLabel.DASHBOARD_BANNERS_DETAIL
      }
    ]
  },
  {
    key: ConstantRouter.DASHBOARD_NEWS,
    name: ConstantLabel.DASHBOARD_NEWS,
    icon: 'el-icon-folder-opened',
    hideChildrenInMenu: true,
    children: [
      {
        key: ConstantRouter.DASHBOARD_NEWS_ADD,
        name: ConstantLabel.DASHBOARD_NEWS_ADD
      },
      {
        key: ConstantRouter.DASHBOARD_NEWS_DETAIL,
        name: ConstantLabel.DASHBOARD_NEWS_DETAIL
      }
    ]
  },
  {
    key: ConstantRouter.DASHBOARD_WORKS,
    name: ConstantLabel.DASHBOARD_WORKS,
    icon: 'el-icon-folder-opened',
    hideChildrenInMenu: true,
    children: [
      {
        key: ConstantRouter.DASHBOARD_WORKS_ADD,
        name: ConstantLabel.DASHBOARD_WORKS_ADD
      },
      {
        key: ConstantRouter.DASHBOARD_WORKS_DETAIL,
        name: ConstantLabel.DASHBOARD_WORKS_DETAIL
      }
    ]
  },
  {
    key: ConstantRouter.DASHBOARD_EXPERTS,
    name: ConstantLabel.DASHBOARD_EXPERTS,
    icon: 'el-icon-folder-opened',
    hideChildrenInMenu: true,
    children: [
      {
        key: ConstantRouter.DASHBOARD_EXPERTS_ADD,
        name: ConstantLabel.DASHBOARD_EXPERTS_ADD
      },
      {
        key: ConstantRouter.DASHBOARD_EXPERTS_DETAIL,
        name: ConstantLabel.DASHBOARD_EXPERTS_DETAIL
      }
    ]
  },
  {
    key: ConstantRouter.DASHBOARD_POPUP,
    name: ConstantLabel.DASHBOARD_POPUP,
    icon: 'el-icon-folder-opened',
    hideChildrenInMenu: true,
    children: [
      {
        key: ConstantRouter.DASHBOARD_POPUP_ADD,
        name: ConstantLabel.DASHBOARD_POPUP_ADD
      },
      {
        key: ConstantRouter.DASHBOARD_POPUP_DETAIL,
        name: ConstantLabel.DASHBOARD_POPUP_DETAIL
      }
    ]
  },
  // {
  //   key: ConstantRouter.DASHBOARD_MINI_BANNERS,
  //   name: ConstantLabel.DASHBOARD_MINI_BANNERS,
  //   icon: 'el-icon-folder-opened',
  //   hideChildrenInMenu: true,
  //   children: [
  //     {
  //       key: ConstantRouter.DASHBOARD_MINI_BANNERS_ADD,
  //       name: ConstantLabel.DASHBOARD_MINI_BANNERS_ADD
  //     },
  //     {
  //       key: ConstantRouter.DASHBOARD_MINI_BANNERS_UPDATE,
  //       name: ConstantLabel.DASHBOARD_MINI_BANNERS_UPDATE
  //     },
  //     {
  //       key: ConstantRouter.DASHBOARD_MINI_BANNERS_DETAIL,
  //       name: ConstantLabel.DASHBOARD_MINI_BANNERS_DETAIL
  //     }
  //   ]
  // },
  // {
  //   key: ConstantRouter.DASHBOARD_MINI_ARTICLE,
  //   name: ConstantLabel.DASHBOARD_MINI_ARTICLE,
  //   icon: 'el-icon-folder-opened',
  //   hideChildrenInMenu: true,
  //   children: [
  //     {
  //       key: ConstantRouter.DASHBOARD_MINI_ARTICLE_ADD,
  //       name: ConstantLabel.DASHBOARD_MINI_ARTICLE_ADD
  //     },
  //     {
  //       key: ConstantRouter.DASHBOARD_MINI_ARTICLE_UPDATE,
  //       name: ConstantLabel.DASHBOARD_MINI_ARTICLE_UPDATE
  //     },
  //     {
  //       key: ConstantRouter.DASHBOARD_MINI_ARTICLE_DETAIL,
  //       name: ConstantLabel.DASHBOARD_MINI_ARTICLE_DETAIL
  //     }
  //   ]
  // }
]

export default menu
