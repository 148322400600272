<template>
  <div class="index-works">
    <div class="works-title">
      <h2>
        {{ $t('interview.title') }}
        <br />
        <sub>{{ $t('interview.sub-title') }}</sub>
      </h2>
    </div>
    <div class="works-content">
      <el-carousel :interval="4000" type="card" v-if="list.length">
        <el-carousel-item v-for="item in list" :key="item.id">
          <div class="works-img" @click="play(item)">
            <img :src="item.showImg" :alt="item.showImg" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <Modal ref="modalRef"></Modal>
  </div>
</template>

<script>
import { newWindowOpen } from '@/utils/tools'
import * as ConstantRouter from '@/constant/router'
import Modal from './modal.vue'

export default {
  components: {
    Modal
  },
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.modalRef = this.fetchList()
  },
  methods: {
    async fetchList() {
      const [err, res] = await this.$serve.jsArticle.getPageList({
        data: {
          pageIndex: 1,
          pageSize: 6,
          jsId: this.jsId,
          articleType: 4
        }
      })
      if (err) return
      this.list = res.records
    },
    toNews(item) {
      newWindowOpen({
        name: ConstantRouter.NEWS,
        params: {
          id: item.id
        }
      })
    },
    play(item) {
      if (item.videoUrl) this.$refs.modalRef.open(item.videoUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
.index-works {
  width: 1200px;
  margin: 80px auto 0;
}
.works-title {
  text-align: center;
  margin-bottom: 40px;
}
.works-img {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.works-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1199px) {
  .index-works {
    width: 100%;
    padding: 0 20px;
  }
}

@media (max-width: 414px) {
  ::v-deep {
    .el-carousel__container {
      height: 150px;
    }
  }
}
</style>
