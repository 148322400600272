export default {
  ['title']: '报名注册',
  ['submit-text']: '注册',
  ['reset-text']: '重置',
  ['email']: '邮箱',
  ['password']: '密码',
  ['first-name']: '姓',
  ['name']: '名',
  ['telephone']: '手机号',
  ['idNumber']: '身份证号',
  ['occupation']: '职业/角色',
  ['country-region']: '地址',
  ['country-region-detail']: '详细地址',
  ['post-code']: '邮政编码',
  ['placeholder-input']: '请输入',
  ['message-submit-success']: '注册成功'
}
