const apis = [
  {
    // 作品打分分页列表
    fn: 'getPageList',
    method: 'POST',
    url: '/api/jsOpusScore/getPageList',
    hideLoading: true
  },
  {
    // 添加作品打分（复审）
    fn: 'add',
    method: 'POST',
    url: '/api/jsOpusScore/add',
    hideLoading: true
  },
  {
    // 添加作品打分（初审）
    fn: 'addFirst',
    method: 'POST',
    url: '/api/jsOpusScore/addFirst',
    hideLoading: true
  },
  {
    // 修改作品打分
    fn: 'update',
    method: 'POST',
    url: '/api/jsOpusScore/update',
    hideLoading: true
  },
  {
    // 删除作品打分
    fn: 'delete',
    method: 'POST',
    url: '/api/jsOpusScore/delete',
    hideLoading: true
  },
  {
    // 作品打分详情
    fn: 'info',
    method: 'GET',
    url: '/api/jsOpusScore/info',
    hideLoading: true
  }
]

export default apis
