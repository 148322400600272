<template>
  <div class="page-guide">
    <div class="container">
      <div class="guide-block">
        <div class="competition-basic" v-if="jsDetail">
          <div class="competition-img">
            <img :src="jsDetail.bannerImg" :alt="jsDetail.bannerImg" />
          </div>
          <div class="competition-info">
            <h1 class="competition-title">{{ jsDetail.name }}</h1>
            <p class="competition-statistics">
              {{ $t('guide.label-deadline') }}:
              {{ jsDetail.stopContributionTime }}
            </p>
          </div>
        </div>
        <div class="competition-schedule" v-if="scheduleDetail">
          <el-steps
            :active="stepsActive"
            align-center
            :direction="smallerScreen ? 'vertical' : 'horizontal'"
          >
            <el-step
              v-for="item in scheduleDetail"
              :key="item.id"
              :title="item.title"
              :description="item.scheduleTime"
            ></el-step>
          </el-steps>
        </div>
      </div>

      <div class="guide-block">
        <div class="competition-guide">
          <div class="guide-head">{{ $t('guide.title') }}</div>
          <div class="guide-main">
            <div v-if="guideDetail" v-html="guideDetail.context"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  data() {
    return {
      jsId: this.$route.params.jsId,
      jsLoading: false,
      jsDetail: null,
      guideLoading: false,
      guideDetail: null,
      scheduleLoading: false,
      scheduleDetail: []
    }
  },
  mounted() {
    this.fetchJsDetail()
  },
  computed: {
    ...mapState({
      screen(state) {
        return state.screen
      }
    }),
    smallerScreen() {
      return ['screen-xs', 'screen-sm'].some((item) => this.screen[item])
    },
    stepsActive() {
      return this.scheduleDetail.filter(({ startTime }) => {
        return moment(startTime).isBefore(new Date(), 'day')
      }).length - 1
    }
  },
  methods: {
    async fetchJsDetail() {
      this.jsLoading = true
      const [err, res] = await this.$serve.jsRecord.info({
        queryId: this.jsId
      })
      this.jsLoading = false
      if (err) return
      this.jsDetail = res
      if (res.jsGuide) this.fetchGuide(res.jsGuide.id)
      this.fetchSchedule()
    },
    async fetchGuide(guideId) {
      this.guideLoading = true
      const [err, res] = await this.$serve.jsGuide.info({
        queryId: guideId
      })
      this.guideLoading = false
      if (err) return
      this.guideDetail = res
    },
    async fetchSchedule() {
      this.scheduleLoading = true
      const [err, res] = await this.$serve.jsSchedule.getPageList({
        data: {
          jsId: this.jsId
        }
      })
      this.scheduleLoading = false
      if (err) return
      this.scheduleDetail = res.records
    }
  }
}
</script>

<style lang="scss" scoped>
.page-guide {
  overflow: hidden;
  min-height: 100vh;
  background: #f8fafc;

  .container {
    width: 1200px;
    margin: 20px auto;
  }
}

.guide-block {
  padding: 20px;
  background: #fff;

  & + .guide-block {
    margin-top: 20px;
  }
}

.competition-basic {
  display: flex;
}

.competition-img {
  width: 300px;
  height: 160px;
  margin-right: 20px;
  background: #f5f5f5;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.competition-info {
  h1 {
    font-size: 20px;
  }
}

.competition-schedule {
  margin-top: 30px;
}

.guide-head {
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: 600;
  padding-left: 10px;
  border-left: 5px solid #5970e3;
}

@media (max-width: 1199px) {
  .page-guide {
    .container {
      width: 100%;
      padding: 10px 10px;
      margin: 0;
    }
  }
}
</style>
