<template>
  <div class="page-news">
    <el-skeleton v-if="loading" :rows="30" animated />
    <div v-else class="news-data">
      <img class="news-img" :src="detail.showImg" :alt="detail.showImg" />
      <div class="container">
        <div class="news-head">
          <h2>{{ detail.title }}</h2>
          <p>发布时间：{{ detail.articleDate }}</p>
        </div>
        <div class="news-main">
          <div v-html="detail.context"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      loading: true,
      detail: null
    }
  },
  mounted() {
    this.fetchDetail()
  },
  methods: {
    async fetchDetail() {
      this.loading = true
      const [err, res] = await this.$serve.jsArticle.info({
        queryId: this.id
      })
      this.loading = false
      if (err) return
      this.detail = res
    }
  }
}
</script>

<style lang="scss" scoped>
.page-news {
  overflow: hidden;
  .container {
    width: 1200px;
    padding: 20px 40px;
    margin: 40px auto;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
}
.news-img {
  display: block;
  width: 100%;
  max-height: 500px;
  object-fit: cover;
}
.news-head {
  margin-bottom: 50px;
  text-align: center;
  p {
    color: #999;
  }
}

@media (max-width: 1199px) {
  .page-news {
    .container {
      width: 100%;
      margin: 0;
      padding: 10px;
    }
  }
}
</style>
