import * as ConstantRouter from '@/constant/router'
import * as ConstantLabel from '@/constant/label'

/**
 * 菜单列表
 * hide 隐藏当前菜单
 * hideChildrenInMenu 隐藏所有子菜单
 * key 路由唯一值
 * name 路由名称
 */
const menu = [
  {
    key: ConstantRouter.JUDGE_REVIEW,
    name: ConstantLabel.JUDGE_REVIEW,
    icon: 'el-icon-folder-opened',
    hideChildrenInMenu: true,
    children: [
      {
        key: ConstantRouter.JUDGE_REVIEW_ADD,
        name: ConstantLabel.JUDGE_REVIEW_ADD
      },
      {
        key: ConstantRouter.JUDGE_REVIEW_DETAIL,
        name: ConstantLabel.JUDGE_REVIEW_DETAIL
      },
    ]
  },
]

export default menu
