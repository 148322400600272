<template>
  <div class="index-banner">
    <el-carousel :autoplay="false" v-if="list.length">
      <el-carousel-item v-for="item in list" :key="item.id">
        <div class="banner-img">
          <img :src="item.imgUrl" :alt="item.imgUrl" />
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="index-banner__top">
      <div class="container">
        <el-dropdown>
          <el-button type="text">
            {{ $t('global.change-language') }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="lang !== 'cn'">
              <el-button
                type="text"
                style="width: 100%; text-align: center"
                size="small"
                @click="changeLang('cn')"
              >
                {{ $t('global.language-cn') }}
              </el-button>
            </el-dropdown-item>
            <el-dropdown-item v-if="lang !== 'en'">
              <el-button
                type="text"
                style="width: 100%; text-align: center"
                size="small"
                @click="changeLang('en')"
              >
                {{ $t('global.language-en') }}
              </el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { localStore } from '@/utils/store'

export default {
  data() {
    return {
      jsId: this.$route.params.jsId,
      list: [],
      lang: localStore.get('lang') || 'cn' // 语言标识
    }
  },
  mounted() {
    this.fetchList()
  },
  methods: {
    changeLang(lang) {
      localStore.set('lang', lang)
      location.reload()
    },
    async fetchList() {
      const [err, res] = await this.$serve.jsBanner.getPageList({
        data: {
          pageIndex: 1,
          pageSize: 10,
          jsId: this.jsId
        }
      })
      if (err) return
      this.list = res.records
    }
  }
}
</script>

<style lang="scss" scoped>
.index-banner {
  position: relative;

  .banner-img {
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .index-banner__top {
    position: absolute;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;
    background: linear-gradient(rgba(0, 0, 0, 0.4), transparent);
    .container {
      width: 1200px;
      margin: 0 auto;
      text-align: right;
    }
  }
}
::v-deep {
  .el-carousel__container {
    height: 650px;
  }
  .el-dropdown-selfdefine {
    color: #fff;
  }
}

@media (max-width: 1199px) {
  ::v-deep {
    .el-carousel__container {
      height: 400px;
    }
  }

  .index-banner .index-banner__top {
    .container {
      width: 100%;
      padding: 0 20px;
    }
  }
}
</style>
