import { isCompetitor } from '@/utils/tools'
import { lang } from '@/config/env'

const apis = [
  {
    // 赛道分页列表
    fn: 'getPageList',
    method: 'POST',
    url: '/api/jsTrack/getPageList',
    hideLoading: true,
    processLang(res) {
      if (isCompetitor()) {
        if (lang === 'cn') return res
        if (!res) return res
        return {
          ...res,
          records: res.records.map((item) => ({
            ...item,
            jsName: item.jsNameEn,
            name: item.nameEn || item.name
          }))
        }
      }
      return res
    }
  },
  {
    // 添加赛道
    fn: 'add',
    method: 'POST',
    url: '/api/jsTrack/add',
    hideLoading: true
  },
  {
    // 修改赛道
    fn: 'update',
    method: 'POST',
    url: '/api/jsTrack/update',
    hideLoading: true
  },
  {
    // 删除赛道
    fn: 'delete',
    method: 'POST',
    url: '/api/jsTrack/delete',
    hideLoading: true
  },
  {
    // 赛道详情
    fn: 'info',
    method: 'GET',
    url: '/api/jsTrack/info',
    hideLoading: true
  }
]

export default apis
