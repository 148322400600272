const apis = [
  {
    // 参赛用户注册
    fn: 'userAdd',
    method: 'POST',
    url: '/api/jsUser/add',
    hideLoading: true
  },
  {
    // 参赛用户登录
    fn: 'userLogin',
    method: 'POST',
    url: '/api/jsUserLogin',
    hideLoading: true
  },
  {
    // 参赛用户退出
    fn: 'userLogout',
    method: 'POST',
    url: '/api/jsUserLogout',
    hideLoading: true
  },
  {
    // 参赛用户信息
    fn: 'getUserInfo',
    method: 'GET',
    url: '/api/getJsUserInfo',
    hideLoading: true
  }
]

export default apis
