import Vue from 'vue'
import VueRouter from 'vue-router'
import { localStore } from '@/utils/store'
import { mode } from './config'
import routes from './routes'
import * as ConstantRouter from '@/constant/router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(...args) {
  return (originalPush as any).call(this, ...args).catch((err) => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(...args) {
  return (originalReplace as any).call(this, ...args).catch((err) => err)
}

const router = new VueRouter({
  mode,
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to?.path.startsWith('/admin')) {
    // 管理后台
    if (to.path.endsWith('/login')) {
      next()
      return
    }
    const token = localStore.get('dashboard-token')
    if (token) {
      if (!to?.path.startsWith('/admin/works')) {
        localStore.remove('dashboard/works')
      }
      next()
    } else {
      next({
        name: ConstantRouter.DASHBOARD_LOGIN
      })
    }
    return
  }

  // 参赛者后台
  if (to?.path.startsWith('/competitor')) {
    const nextByParams = () => {
      if (to.name && !to.params.jsId && from.params.jsId) {
        next({
          name: to.name,
          params: {
            jsId: from.params.jsId,
            ...to.params
          }
        })
        return
      }
      next()
    }

    if (to.path.endsWith('/login') || to.path.endsWith('/register')) {
      nextByParams()
      return
    }
    const token = localStore.get('competitor-token')
    if (token) {
      nextByParams()
    } else {
      next({
        name: ConstantRouter.COMPETITOR_LOGIN,
        params: {
          jsId: to.params.jsId
        }
      })
    }
    return
  }

  // 评审后台
  if (to?.path.startsWith('/judge')) {
    const nextByParams = () => {
      if (to.name && !to.params.jsId && from.params.jsId) {
        next({
          name: to.name,
          params: {
            jsId: from.params.jsId,
            ...to.params
          },
          query: {
            ...(from.query || {})
          }
        })
        return
      }
      next()
    }

    if (to.path.endsWith('/login')) {
      nextByParams()
      return
    }
    const token = localStore.get('judge-token')
    if (token) {
      nextByParams()
    } else {
      next({
        name: ConstantRouter.JUDGE_LOGIN,
        params: {
          jsId: to.params.jsId
        }
      })
    }
    return
  }
  
  next()
})

export default router
