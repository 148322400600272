export default {
  ['submit-text']: '搜索',
  ['reset-text']: '重置',
  ['add-text']: '添加作品',
  ['name']: '作品名称',
  ['title']: '作品简介',
  ['faceimg']: '作品版图',
  ['faceimg-tip']:
    '作品版面要求为jpg格式文件，A2(420mm×594mm)，竖版，文件大小不超过10M',
  ['tip']:
    '除作品版面图、缩略图外，您可以补充上传设计细节图、结构图、效果图，以更好的展示设计的完整性和落地可行性，同时更好地向评委展示您的作品。图片要求为jpg格式文件，210*210mm，不超过3张，单个文件大小不超过10M',
  ['format-tip']:
    '图片要求为jpg格式文件，210*210mm，不超过3张，单个文件大小不超过3M',
  ['video-format-tip']: '单个文件大小不超过50M',
  ['status']: '提交状态',
  ['status-on']: '已提交',
  ['status-off']: '待提交',
  ['competition']: '选择竞赛',
  ['categories']: '选择赛道',
  ['thumbnail']: '缩略图',
  ['imgdetail']: '补充细节图',
  ['effectpicture']: '效果图',
  ['chartlet']: '说明图',
  ['operation']: '操作',
  ['operation-detail']: '详情',
  ['operation-edit']: '修改',
  ['operation-delete']: '删除',
  ['operation-submit']: '提交',

  ['operation-submit-confirm-title']: '提示',
  ['operation-submit-confirm-message']: '提交后将无法进行修改，是否确定提交',
  ['operation-submit-confirm-sure']: '确定',
  ['operation-submit-confirm-cancel']: '取消',

  ['confirm-delete-title']: '确认删除吗',
  ['message-delete-success']: '删除成功',

  ['placeholder-input']: '请输入',
  ['placeholder-select']: '请选择',

  ['add-submit-text']: '保存',
  ['add-cancel-text']: '返回',

  ['message-add-success']: '作品添加成功',
  ['message-edit-success']: '作品编辑成功',
  ['message-entries-delete-success']: '作品删除成功',
  ['message-entries-submit-success']: '作品提交成功'
}
