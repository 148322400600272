import RouterHolder from '@/components/router-holder'
import * as ConstantRouter from '@/constant/router'

const route = {
  path: 'mini-article',
  name: ConstantRouter.DASHBOARD_MINI_ARTICLE,
  component: RouterHolder,
  redirect: '/admin/mini-article',
  children: [
    {
      path: '',
      component: () => import(/* webpackChunkName: "dashboard-mini-article" */ '@/dashboard/mini-article')
    },
    {
      path: 'add',
      name: ConstantRouter.DASHBOARD_MINI_ARTICLE_ADD,
      component: () =>
        import(/* webpackChunkName: "dashboard-mini-article-add" */ '@/dashboard/mini-article/add'),
    },
    {
      path: 'update/:id',
      name: ConstantRouter.DASHBOARD_MINI_ARTICLE_UPDATE,
      component: () =>
        import(/* webpackChunkName: "dashboard-mini-article-add" */ '@/dashboard/mini-article/add'),
    },
    {
      path: 'detail/:id',
      name: ConstantRouter.DASHBOARD_MINI_ARTICLE_DETAIL,
      component: () =>
        import(/* webpackChunkName: "dashboard-mini-article-detail" */ '@/dashboard/mini-article/detail'),
    }
  ]
}

export default route
