export default {
  ['title']: '重置密码',
  ['email']: '邮箱',
  ['captcha']: '验证码',
  ['captcha-btn']: '获取验证码',
  ['password']: '密码',
  ['confirm-password']: '确认密码',
  ['placeholder-input']: '请输入',
  ['add-submit-text']: '保存',
  ['add-cancel-text']: '取消'
}
