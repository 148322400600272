const presetElements = [
  {
    type: 'color',
    element: 'el-color-picker'
  },
  {
    type: 'color-picker',
    element: 'el-color-picker'
  }
]

export default presetElements
