import RouterHolder from '@/components/router-holder'
import * as ConstantRouter from '@/constant/router'

const route = {
  path: 'members',
  name: ConstantRouter.COMPETITOR_MEMBERS,
  component: RouterHolder,
  redirect: {
    name: ConstantRouter.COMPETITOR_MEMBERS_LIST
  },
  children: [
    {
      path: '',
      name: ConstantRouter.COMPETITOR_MEMBERS_LIST,
      component: () =>
        import(
          /* webpackChunkName: "competitor-members" */ '@/competitor/members'
        )
    },
    {
      path: 'add',
      name: ConstantRouter.COMPETITOR_MEMBERS_ADD,
      component: () =>
        import(
          /* webpackChunkName: "competitor-members-add" */ '@/competitor/members/add'
        )
    },
    {
      path: 'update/:id',
      name: ConstantRouter.COMPETITOR_MEMBERS_UPDATE,
      component: () =>
        import(
          /* webpackChunkName: "competitor-members-add" */ '@/competitor/members/add'
        )
    },
    {
      path: 'detail/:id',
      name: ConstantRouter.COMPETITOR_MEMBERS_DETAIL,
      component: () =>
        import(
          /* webpackChunkName: "competitor-members-detail" */ '@/competitor/members/detail'
        )
    }
  ]
}

export default route
