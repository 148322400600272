import enLocale from 'element-ui/lib/locale/lang/en'
import global from './global'
import process from './process'
import news from './news'
import works from './works'
import interview from './interview'
import guide from './guide'
import register from './register'
import login from './login'
import layout from './layout'
import members from './members'
import entries from './entries'
import password from './password'

const en = {
  global,
  process,
  news,
  works,
  interview,
  guide,
  register,
  login,
  layout,
  members,
  entries,
  password,
  ...enLocale
}

export default en
