<template>
  <div class="layout-competitor">
    <!-- <el-alert
      title="“金星奖”投稿页面正在建设中，请联系组委会了解参赛资料及更多资讯"
      type="warning"
      :closable="false"
    ></el-alert> -->
    <layout-basic
      :title="$t('layout.title')"
      :menu="menu"
      :user="competitorInfo"
    >
      <template #height-right>
        <el-dropdown>
          <span class="el-dropdown-link">
            <span class="user-name" slot="reference" v-if="competitorInfo">
              {{ competitorInfo.firstName }} {{ competitorInfo.name }}
            </span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <el-button
                type="text"
                style="width: 100%; text-align: center"
                size="small"
                @click="passwordReset"
              >
                {{ $t('layout.password-reset') }}
              </el-button>
            </el-dropdown-item>
            <el-dropdown-item>
              <el-button
                type="text"
                style="width: 100%; text-align: center"
                size="small"
                @click="logout"
              >
                {{ $t('layout.logout') }}
              </el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </layout-basic>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LayoutBasic from '@/layout/layout-basic'
import menu from '@/config/menu-competitor'
import { localStore } from '@/utils/store'
import * as ConstantRouter from '@/constant/router'

export default {
  components: {
    LayoutBasic
  },
  data() {
    return {
      menu
    }
  },
  computed: {
    ...mapState({
      competitorInfo(state) {
        return state.competitorInfo
      }
    })
  },
  mounted() {
    if (!this.competitorInfo) this.$store.dispatch('fetchCompetitorInfo')
  },
  methods: {
    passwordReset() {
      this.$router.push({
        name: ConstantRouter.COMPETITOR_PASSWORD_RESET
      })
    },
    logout() {
      localStore.remove('competitor-token')
      location.reload()
    }
  }
}
</script>
