<template>
  <div class="com-loaidng">
    <div
      class="g-loading-block"
      v-for="item in count"
      :key="item"
      :style="{ height: height + 'px' }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default: 4
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style lang="scss" scoped>
.g-loading-block + .g-loading-block {
  margin-top: 12px;
}
</style>
F