<template>
  <div class="index-process">
    <div class="process-decorate">
      <div class="process-decorate__top"></div>
      <div class="process-decorate__bottom"></div>
    </div>
    <div class="process-main">
      <div class="process-title">
        <h2>
          {{ $t('process.title') }}
          <br />
          <sub>{{ $t('process.sub-title') }}</sub>
        </h2>
      </div>
      <div class="process-know">
        <div class="process-know__col">
          <div
            class="process-know__item process-know__item-sign"
            @click="toSign"
          >
            <div class="process-know__item-inner">
              <h4>{{ $t('process.label-sign') }}</h4>
              <p>{{ $t('process.label-detail') }}》》</p>
            </div>
          </div>
          <div
            class="process-know__item process-know__item-submit"
            @click="toCompetitor"
          >
            <div class="process-know__item-inner">
              <h4>{{ $t('process.label-submit') }}</h4>
              <p>{{ $t('process.label-detail') }}》》</p>
            </div>
          </div>
        </div>
        <div class="process-know__col">
          <div
            class="process-know__item process-know__item-guide"
            @click="toGuide"
          >
            <div class="process-know__item-inner">
              <h4>{{ $t('process.label-guide') }}</h4>
              <p>{{ $t('process.label-detail') }}》》</p>
            </div>
          </div>
          <!-- <div class="process-know__item">
            <h4>操作手册</h4>
            <p>查看详情》》</p>
          </div> -->
        </div>
        <div class="process-know__col">
          <div
            class="process-know__item process-know__item-judge"
            @click="toJudge"
          >
            <div class="process-know__item-inner">
              <h4>{{ $t('process.label-judge') }}</h4>
              <p>{{ $t('process.label-detail') }}》》</p>
            </div>
          </div>
        </div>
      </div>
      <div class="process-flow">
        <div class="process-flow__item" v-for="item in schedule" :key="item.id">
          <div class="process-flow__icon">
            <img v-if="item.logoImg" :src="item.logoImg" :alt="item.logoImg" />
          </div>
          <div class="process-flow__info">
            <p class="process-flow__title">
              {{ item.title }}
            </p>
            <p class="process-flow__scheduleTime">
              {{ item.scheduleTime }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as ConstantRouter from '@/constant/router'
import { newWindowOpen } from '@/utils/tools'

export default {
  props: {
    detail: Object
  },
  data() {
    return {
      jsId: this.$route.params.jsId,
      schedule: []
    }
  },
  mounted() {
    if (!this.jsId) {
      this.jsId = this.detail.id
    }
    this.fetchSchedule()
  },
  methods: {
    async fetchSchedule() {
      const [err, res] = await this.$serve.jsSchedule.getPageList({
        data: {
          pageIndex: 1,
          pageSize: 10,
          jsId: this.jsId
        }
      })
      if (err) return
      this.schedule = res.records
    },
    // 报名参赛
    toSign() {
      newWindowOpen({
        name: ConstantRouter.COMPETITOR_REGISTER,
        params: {
          jsId: this.jsId
        }
      })
    },
    // 提交作品 跳转至参赛者后台
    toCompetitor() {
      if (!this.detail.scoreOpen) {
        this.$alert('当前时段禁止提交作品', '提示', {
          confirmButtonText: '确定'
        })
        return
      }
      newWindowOpen({
        name: ConstantRouter.COMPETITOR,
        params: {
          jsId: this.jsId
        }
      })
    },
    toGuide() {
      newWindowOpen({
        name: ConstantRouter.GUIDE,
        params: {
          jsId: this.jsId
        }
      })
    },
    // 专家评审登录
    toJudge() {
      if (!this.detail) return
      if (!this.detail.opusOpen) {
        this.$alert('当前非赛事评审时段', '提示', {
          confirmButtonText: '确定'
        })
        return
      }
      // if (+new Date(this.detail.stopContributionTime) > +new Date()) {
      //   this.$alert('当前非赛事评审时段', '提示', {
      //     confirmButtonText: '确定'
      //   })
      //   return
      // }
      newWindowOpen({
        name: ConstantRouter.JUDGE_REVIEW,
        params: {
          jsId: this.jsId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.index-process {
  position: relative;
  padding: 60px 0;
}

.process-decorate {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
}
.process-decorate__top {
  height: 360px;
  background: #fff;
}
.process-decorate__bottom {
  flex: auto;
  background-image: linear-gradient(to top, #9795f0 0%, #fbc8d4 100%);
}
.process-main {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}
.process-title {
  text-align: center;
  margin-bottom: 40px;
}
.process-know {
  height: 300px;
  display: flex;
}
.process-know__col {
  flex: auto;
  display: flex;
  flex-direction: column;
}
.process-know__item {
  flex: auto;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.process-know__item-inner {
  background: rgba(0, 0, 0, 0.6);
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 32px 24px;
  color: #fff;
  cursor: pointer;
}

.process-know__item-sign {
  background-image: url('~@/assets/images/office1.jpg');
}

.process-know__item-submit {
  background-image: url('~@/assets/images/office2.jpg');
}

.process-know__item-guide {
  background-image: url('~@/assets/images/office3.jpg');
}

.process-know__item-judge {
  background-image: url('~@/assets/images/office4.jpg');
}
.process-flow {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
.process-flow__item {
  width: 180px;
  height: 200px;
  background: #ccc;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.process-flow__icon {
  height: 120px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    display: block;
  }
}
.process-flow__info {
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #000;
  color: #fff;
}
.process-flow__title {
  margin-bottom: 4px;
  text-align: center;
}

@media (max-width: 1199px) {
  .process-main {
    width: 100%;
  }

  .process-flow {
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .process-main {
    width: 100%;
  }

  .process-know {
    flex-direction: column;
    height: auto;
  }

  .process-know__item {
    height: 240px;
  }

  .process-flow {
    flex-wrap: wrap;
    padding: 0 4%;
  }
}

@media (max-width: 650px) {
}

@media (max-width: 414px) {
}

@media (max-width: 390px) {
  .index-process {
    padding: 20px 0;
  }
  .process-flow__item {
    width: 160px;
  }

  .process-flow__icon {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .process-flow__info {
    font-size: 14px;
  }
}

@media (max-width: 320px) {
}
</style>
