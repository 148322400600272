import RouterHolder from '@/components/router-holder'
import * as ConstantRouter from '@/constant/router'

const route = {
  path: 'experts',
  name: ConstantRouter.DASHBOARD_EXPERTS,
  component: RouterHolder,
  redirect: '/admin/experts',
  children: [
    {
      path: '',
      component: () =>
        import(
          /* webpackChunkName: "dashboard-experts" */ '@/dashboard/experts'
        )
    },
    {
      path: 'add',
      name: ConstantRouter.DASHBOARD_EXPERTS_ADD,
      component: () =>
        import(
          /* webpackChunkName: "dashboard-experts-add" */ '@/dashboard/experts/add'
        )
    },
    {
      path: 'update/:id',
      name: ConstantRouter.DASHBOARD_EXPERTS_UPDATE,
      component: () =>
        import(
          /* webpackChunkName: "dashboard-experts-add" */ '@/dashboard/experts/add'
        )
    },
    {
      path: 'detail/:id',
      name: ConstantRouter.DASHBOARD_EXPERTS_DETAIL,
      component: () =>
        import(
          /* webpackChunkName: "dashboard-experts-detail" */ '@/dashboard/experts/detail'
        )
    }
  ]
}

export default route
