import * as ConstantRouter from '@/constant/router'

const route = {
  path: 'password-reset',
  name: ConstantRouter.COMPETITOR_PASSWORD_RESET,
  component: () =>
    import(
      /* webpackChunkName: "competitor-password-reset" */ '@/competitor/password-reset'
    )
}

export default route
