<template>
  <div class="layout-dashboard" v-if="judgeInfo">
    <layout-basic
      title="评审系统"
      :user="judgeInfo"
      :menu="menuList"
      siderWidth="auto"
    ></layout-basic>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LayoutBasic from '@/layout/layout-basic'
import menu from '@/config/menu-judge'
import * as ConstantRouter from '@/constant/router'

export default {
  components: {
    LayoutBasic
  },
  data() {
    return {
      menu,
      jsId: this.$route.params.jsId,
      trackList: []
    }
  },
  computed: {
    ...mapState({
      judgeInfo(state) {
        return state.judgeInfo
      },
      currentRecordFromJudge(state) {
        return state.currentRecordFromJudge
      },
      menuList(state) {
        return (state.judgeInfo?.trackList || []).map((item) => ({
          key: ConstantRouter.JUDGE_REVIEW_LIST,
          name: item.name,
          query: {
            track: item.id
          }
        }))
      }
    })
  },
  watch: {
    menuList: {
      handler(val) {
        if (!Object.keys(this.$route.query).length && val[0]) {
          this.$router.push({
            query: val[0].query
          })
        }
      }
    }
  },
  mounted() {
    if (!this.judgeInfo) this.$store.dispatch('fetchJudgeInfo')
    if (!this.currentRecordFromJudge && this.jsId)
      this.$store.dispatch('fetchCurrentRecordFromJudge', this.jsId)
  }
}
</script>
