const apis = [
  {
    // 轮播图分页列表
    fn: 'getPageList',
    method: 'POST',
    url: '/api/mpBanner/getPageList',
    hideLoading: true
  },
  {
    // 添加轮播图
    fn: 'add',
    method: 'POST',
    url: '/api/mpBanner/add',
    hideLoading: true
  },
  {
    // 修改轮播图
    fn: 'update',
    method: 'POST',
    url: '/api/mpBanner/update',
    hideLoading: true
  },
  {
    // 删除轮播图
    fn: 'delete',
    method: 'POST',
    url: '/api/mpBanner/delete',
    hideLoading: true
  },
  {
    // 轮播图详情
    fn: 'info',
    method: 'GET',
    url: '/api/mpBanner/info',
    hideLoading: true
  }
]

export default apis
