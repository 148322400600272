import RouterHolder from '@/components/router-holder'
import * as ConstantRouter from '@/constant/router'

const route = {
  path: 'works',
  name: ConstantRouter.DASHBOARD_WORKS,
  component: RouterHolder,
  redirect: '/admin/works',
  children: [
    {
      path: '',
      component: () =>
        import(/* webpackChunkName: "dashboard-works" */ '@/dashboard/works')
    },
    {
      path: 'detail/:id',
      name: ConstantRouter.DASHBOARD_WORKS_DETAIL,
      component: () =>
        import(
          /* webpackChunkName: "dashboard-works-detail" */ '@/dashboard/works/detail'
        )
    }
  ]
}

export default route
