export default {
  ['submit-text']: 'Search',
  ['reset-text']: 'Reset',
  ['add-text']: 'Add Entry',
  ['name']: 'Name of Entry',
  ['title']: 'Entry Description',
  ['faceimg']: 'Layout',
  ['faceimg-tip']:
    'Format of the entry: JPG file, A2 (420mm*594mm), 300dpi, VERTICAL VERSION, NO MORE THAN 5M. ONE ENTRY IS LIMITED TO ONE LAYOUT.',
  ['tip']: 'In addition to the layout, you can also upload design detail drawings, structural drawings to show the integrity and feasibility of the design, also to better present your work to the judges. (no more than 3 pictures)',
  ['format-tip']: 'Format of detail picture: 210*210mm, and the size of a single file does not exceed 3M.',
  ['video-format-tip']: 'Format of detail video: the size of a single file does not exceed 50M.',
  ['status']: 'Status',
  ['competition']: 'Competition',
  ['categories']: 'Categories',
  ['thumbnail']: 'Thumbnail',
  ['imgdetail']: 'Details',
  ['effectpicture']: 'Effect',
  ['chartlet']: 'Chartlet',
  ['status-on']: 'Submitted',
  ['status-off']: 'Unsubmitted',
  ['operation']: 'Edit',
  ['operation-detail']: 'Details',
  ['operation-edit']: 'Edit',
  ['operation-delete']: 'Delete',
  ['operation-submit']: 'Submit',

  ['operation-submit-confirm-title']: 'Warning',
  ['operation-submit-confirm-message']: 'Are you sure submit now?',
  ['operation-submit-confirm-sure']: 'Sure',
  ['operation-submit-confirm-cancel']: 'Cancel',

  ['confirm-delete-title']: 'Confirm Delete',
  ['message-delete-success']: 'Delete Success',

  ['placeholder-input']: 'Please Input',
  ['placeholder-select']: 'Please Select',

  ['add-submit-text']: 'Save',
  ['add-cancel-text']: 'Cancel',

  ['message-add-success']: 'Add Success',
  ['message-edit-success']: 'Edit Suceess',
  ['message-entries-delete-success']: 'Delete Success',
  ['message-entries-submit-success']: 'Submit Success'
}
