<template>
  <div class="index-news">
    <div class="news-title">
      <h2>
        {{ $t('news.title') }}
        <br />
        <sub>{{ $t('news.sub-title') }}</sub>
      </h2>
    </div>
    <div class="news-content">
      <div class="news-content__control">
        <el-button
          :type="articleType === 2 ? 'primary' : 'default'"
          @click="checkNews(2)"
        >
          {{ $t('news.action-notice') }}
        </el-button>
        <el-button
          :type="articleType === 1 ? 'primary' : 'default'"
          @click="checkNews(1)"
        >
          {{ $t('news.action-advices') }}
        </el-button>
        <!-- @click="checkNews(3)" -->
        <el-button
          :type="articleType === 3 ? 'primary' : 'default'"
          @click="checkNews(3)"
        >
          {{ $t('news.action-history') }}
        </el-button>
      </div>
      <el-timeline>
        <el-timeline-item
          v-for="(item, index) in list"
          :key="index"
          :timestamp="item.articleDate"
        >
          <a v-if="item.contentType === 2" :href="item.outUrl" target="_blank">
            {{ item.title }}
          </a>
          <el-button
            v-else
            class="news-content__title"
            type="text"
            style="padding: 0"
            @click="toNews(item)"
          >
            {{ item.title }}
          </el-button>
        </el-timeline-item>
      </el-timeline>
      <a href="http://products.ykwjdesign.com/category/notice" target="_blank">
        <el-button class="news-content__title" type="text" style="padding: 0">
          {{ $t('news.events-history') }}
        </el-button>
      </a>
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        @current-change="currentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { newWindowOpen } from '@/utils/tools'
import * as ConstantRouter from '@/constant/router'

export default {
  data() {
    return {
      list: [],
      pageSize: 5,
      total: 0,
      articleType: 2 // 文章分类 1新闻 2通知 3往届作品
    }
  },
  mounted() {
    this.fetchList()
  },
  methods: {
    async fetchList(pageIndex = 1) {
      const [err, res] = await this.$serve.jsArticle.getPageList({
        data: {
          pageIndex,
          pageSize: this.pageSize,
          jsId: this.jsId,
          articleType: this.articleType
        }
      })
      if (err) return
      this.list = res.records
      this.total = res.total
    },
    async fetchHistoryList(pageIndex = 1) {
      const [err, res] = await this.$serve.jsArticle.getPageList({
        data: {
          pageIndex,
          pageSize: this.pageSize,
          jsId: this.jsId,
          articleType: 3,
          contentType: 2
        }
      })
      if (err) return
      this.list = res.records
      this.total = res.total
    },
    currentChange(pageIndex) {
      this.fetchList(pageIndex)
    },
    checkNews(articleType) {
      this.articleType = articleType
      this.fetchList(1)
    },
    checkHistoryNews() {
      this.articleType = 3
      this.fetchHistoryList(1)
    },
    toNews(item) {
      newWindowOpen({
        name: ConstantRouter.NEWS,
        params: {
          id: item.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.index-news {
  width: 1200px;
  margin: 80px auto 0;
}
.news-title {
  text-align: center;
  margin-bottom: 40px;
}
.news-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.news-content__title {
  max-width: 1200px;
  word-break: break-all;
  white-space: break-spaces;
  text-align: left;
  a {
    color: #409eff;
  }
}
.news-content__control {
  margin-bottom: 30px;
}

.el-pagination {
  margin-top: 20px;
}

@media (max-width: 1199px) {
  .index-news {
    width: 100%;
  }

  .process-flow {
    flex-wrap: wrap;
  }
}
</style>
