import { isView, isCompetitor } from '@/utils/tools'
import { lang } from '@/config/env'

const apis = [
  {
    // 竞赛管理分页列表
    fn: 'getPageList',
    method: 'POST',
    url: '/api/jsRecord/getPageList',
    hideLoading: true,
    processLang(res) {
      if (isCompetitor()) {
        if (lang === 'cn') return res
        if (!res) return res
        return {
          ...res,
          records: res.records.map((item) => ({
            ...item,
            name: item.nameEn
          }))
        }
      }
      return res
    }
  },
  {
    // 添加竞赛管理
    fn: 'add',
    method: 'POST',
    url: '/api/jsRecord/add',
    hideLoading: true
  },
  {
    // 修改竞赛管理
    fn: 'update',
    method: 'POST',
    url: '/api/jsRecord/update',
    hideLoading: true
  },
  {
    // 删除竞赛管理
    fn: 'delete',
    method: 'POST',
    url: '/api/jsRecord/delete',
    hideLoading: true
  },
  {
    // 竞赛管理详情
    fn: 'info',
    method: 'GET',
    url: '/api/jsRecord/info',
    hideLoading: true,
    processLang(res) {
      if (!isView()) return res
      if (lang === 'cn') return res
      if (!res) return res
      return {
        ...res,
        name: res.nameEn
      }
    }
  }
]

export default apis
