export default {
  ['title']: '报名系统',

  ['menu-team']: '队员管理',
  ['menu-team-add']: '队员添加',
  ['menu-team-update']: '队员编辑',
  ['menu-team-detail']: '队员详情',

  ['menu-entries']: '作品管理',
  ['menu-entries-add']: '作品添加',
  ['menu-entries-update']: '作品编辑',
  ['menu-entries-detail']: '作品详情',
  
  ['password-reset']: '重置密码',
  ['logout']: '退出登录'
}
