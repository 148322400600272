<template>
  <div class="index-video">
    <div class="index-video__inner">
      <video
        v-if="detail"
        :src="detail.videoUrl"
        controls
        muted
        autoplay
      ></video>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: Object
  }
}
</script>

<style scoped lang="scss">
.index-video {
  padding: 80px 0 40px;
  video {
    background-color: #000;
    width: 100%;
    max-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.index-video__inner {
  max-width: 1200px;
  margin: 0 auto;
}
</style>
