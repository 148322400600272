export default {
  ['submit-text']: '搜索',
  ['reset-text']: '重置',
  ['add-text']: '添加队员',
  ['name']: '姓名',
  ['phone']: '手机号',
  ['role']: '角色',
  ['role-leader']: '队长',
  ['role-member']: '团队成员',
  ['role-instructor']: '指导老师',
  ['competition']: '选择竞赛',
  ['idNum']: '身份证号',
  ['address']: '地址',
  ['unit']: '单位/学校',
  ['operation']: '操作',
  ['operation-detail']: '详情',
  ['operation-edit']: '修改',
  ['operation-delete']: '删除',

  ['confirm-delete-title']: '确认删除吗',
  ['message-delete-success']: '删除成功',

  ['placeholder-input']: '请输入',
  ['placeholder-select']: '请选择',

  ['add-tip-1']: '以小组形式参赛,每组选手不宜超过3人。',
  ['add-tip-2']: '有指导教师参与的团队, 至多可添加2位指导教师(排名不分先后)。',
  ['add-tip-3']: ' 以小组为单位报名时, 仅需团队联系人注册登录, 并完整填写团队成员姓名, 身份信息和联系方式, 无需重复注册投递。',

  ['add-submit-text']: '保存',
  ['add-cancel-text']: '返回',
  
  ['message-add-success']: '队员添加成功',
  ['message-edit-success']: '队员编辑成功',
  ['message-member-delete-success']: '队员删除成功'
}
