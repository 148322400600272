<template>
  <div class="web-article">
    <Loading v-if="loading" :count="6"></Loading>
    <div v-else v-html="detail.context"></div>
  </div>
</template>

<script>
import Loading from '@/components/loading'

export default {
  components: {
    Loading
  },
  data() {
    return {
      loading: true,
      id: this.$route.params.id,
      detail: null
    }
  },
  mounted() {
    this.fetchDetail()
  },
  methods: {
    async fetchDetail() {
      this.loading = true
      const [err, res] = await this.$serve.gwCase.info({
        queryId: this.id
      })
      this.loading = false
      if (err) return
      this.detail = res
    }
  }
}
</script>

<style lang="scss" scoped></style>
