const apis = [
  {
    // 团队成员分页列表
    fn: 'getPageList',
    method: 'POST',
    url: '/api/jsTeamMember/getPageList',
    hideLoading: true
  },
  {
    // 添加团队成员
    fn: 'add',
    method: 'POST',
    url: '/api/jsTeamMember/add',
    hideLoading: true
  },
  {
    // 修改团队成员
    fn: 'update',
    method: 'POST',
    url: '/api/jsTeamMember/update',
    hideLoading: true
  },
  {
    // 删除团队成员
    fn: 'delete',
    method: 'POST',
    url: '/api/jsTeamMember/delete',
    hideLoading: true
  },
  {
    // 团队成员详情
    fn: 'info',
    method: 'GET',
    url: '/api/jsTeamMember/info',
    hideLoading: true
  }
]

export default apis
