// import Index from '@/views/index/index.vue'
import Event from '@/views/event/index.vue'
import Guide from '@/views/guide/index.vue'
import News from '@/views/news/index.vue'
import * as ConstantRouter from '@/constant/router'

const routes = [
  // {
  //   path: '',
  //   name: ConstantRouter.INDEX,
  //   // component: Index
  //   // 暂时不显示比赛列表给用户
  //   component: () =>
  //     import(/* webpackChunkName: "dashboard-login" */ '@/dashboard/login')
  // },
  {
    path: ':jsId',
    name: ConstantRouter.EVENT,
    component: Event
  },
  {
    path: 'guide/:jsId',
    name: ConstantRouter.GUIDE,
    component: Guide
  },
  {
    path: 'news/:id',
    name: ConstantRouter.NEWS,
    component: News
  }
]

export default routes
