<template>
  <container-query :query="query" v-model="screen">
    <router-view></router-view>
  </container-query>
</template>

<script>
import { ContainerQuery } from 'vue-container-query'

const query = {
  'screen-xs': {
    maxWidth: 575
  },
  'screen-sm': {
    minWidth: 576,
    maxWidth: 767
  },
  'screen-md': {
    minWidth: 768,
    maxWidth: 991
  },
  'screen-lg': {
    minWidth: 992,
    maxWidth: 1199
  },
  'screen-xl': {
    minWidth: 1200,
    maxWidth: 1599
  },
  'screen-xxl': {
    minWidth: 1600
  }
}

export default {
  components: { ContainerQuery },
  data() {
    return {
      query,
      screen: {},
      drawerVisible: false
    }
  },
  watch: {
    screen(data) {
      this.$store.commit('screenUpdate', data)
    }
  }
}
</script>
