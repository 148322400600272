const apis = [
  {
    // 专家分页列表
    fn: 'getPageList',
    method: 'POST',
    url: '/api/jsExpert/getPageList',
    hideLoading: true
  },
  {
    // 添加专家
    fn: 'add',
    method: 'POST',
    url: '/api/jsExpert/add',
    hideLoading: true
  },
  {
    // 修改专家
    fn: 'update',
    method: 'POST',
    url: '/api/jsExpert/update',
    hideLoading: true
  },
  {
    // 删除专家
    fn: 'delete',
    method: 'POST',
    url: '/api/jsExpert/delete',
    hideLoading: true
  },
  {
    // 专家详情
    fn: 'info',
    method: 'GET',
    url: '/api/jsExpert/info',
    hideLoading: true
  },
  {
    // 专家登录
    fn: 'userLogin',
    method: 'POST',
    url: '/api/jsExpert/userLogin',
    hideLoading: true
  },
  {
    // 导出专家通过的作品列表
    fn: 'exportScoreResult',
    method: 'POST',
    url: '/api/jsExpert/exportScoreResult',
    hideLoading: true,
    blob: true,
    responseType: 'arraybuffer'
  }
]

export default apis
