import bannersRoute from './banners'
import newsRoute from './news'
import competitionRoute from './competition'
import worksRoute from './works'
import expertsRoute from './experts'
import popupRoute from './popup'
import miniArticleRoute from './mini-article'
import miniBannersRoute from './mini-banners'

const routes = [
  competitionRoute,
  bannersRoute,
  newsRoute,
  worksRoute,
  expertsRoute,
  popupRoute,
  miniArticleRoute,
  miniBannersRoute
]

export default routes
