const apis = [
  {
    // 清除excel导入缓存
    fn: 'clearExcelCache',
    method: 'POST',
    url: '/api/jsImport/clearExcelCache',
    hideLoading: true
  },
]

export default apis
