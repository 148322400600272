import serve from '@/serve'
import { localStore } from '@/utils/store'

const root = {
  state: {
    menuCollapse: false, // 菜单是否折叠
    screen: {}, // 当前屏幕大小
    exception: {}, // 业务接口报错或者不合法信息
    loading: {}, // 请求状态集合
    competitorInfo: null,
    judgeInfo: null,
    dashboardInfo: null,
    currentRecordFromJudge: null // 当前竞赛
  },
  mutations: {
    menuCollapseUpdate(state, payload) {
      state.menuCollapse = payload
    },
    screenUpdate(state, payload) {
      state.screen = payload
    },
    exceptionUpdate(state, payload) {
      state.exception = {
        ...state.exception,
        ...payload
      }
    },
    loadingUpdate(state, payload) {
      state.loading = {
        ...state.loading,
        ...payload
      }
    },
    userinfoUpdate(state, payload) {
      state.userinfo = payload
    },
    competitorInfoUpdate(state, payload) {
      state.competitorInfo = payload
    },
    judgeInfoUpdate(state, payload) {
      state.judgeInfo = payload
    },
    dashboardInfoUpdate(state, payload) {
      state.dashboardInfo = payload
    },
    currentRecordFromJudgeUpdate(state, payload) {
      state.currentRecordFromJudge = payload
    }
  },
  actions: {
    async fetchCompetitorInfo({ commit }) {
      const [err, res] = await serve.user.getUserInfo()
      if (err) {
        return
      }
      commit('competitorInfoUpdate', res)
    },
    async fetchDashboardInfo({ commit }) {
      const [err, res] = await serve.system.getSysUserInfo()
      if (err) {
        return
      }
      commit('dashboardInfoUpdate', res)
    },
    async fetchJudgeInfo({ commit }) {
      const [err, res] = await serve.jsExpert.info({
        queryId: localStore.get('judge-id')
      })
      if (err) {
        return
      }
      commit('judgeInfoUpdate', res)
    },
    async fetchCurrentRecordFromJudge({ commit }, id) {
      const [err, res] = await serve.jsRecord.info({
        queryId: id
      })
      if (err) return
      commit('currentRecordFromJudgeUpdate', res)
    }
  }
}

export default root
