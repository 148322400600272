<template>
  <div class="com-header-view">
    <div class="com-header-view__left">
      <span class="com-header-view__triggle" @click="menuCollapseToggle">
        <i v-if="menuCollapse" class="el-icon-s-unfold"></i>
        <i v-else class="el-icon-s-fold"></i>
      </span>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <template v-for="item in matchedRoutes">
          <el-breadcrumb-item
            v-if="item.enabled"
            :key="item.name"
            :to="{
              name: item.name
            }"
          >
            {{ item.label }}
          </el-breadcrumb-item>
          <el-breadcrumb-item v-else :key="item.name">
            {{ item.label }}
          </el-breadcrumb-item>
        </template>
      </el-breadcrumb>
    </div>
    <div class="com-header-view__right">
      <slot name="height-right">
        <el-dropdown>
          <span class="el-dropdown-link">
            <span class="user-name" slot="reference" v-if="user">
              {{ user.firstName }} {{ user.name }}
            </span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <el-button
                type="text"
                style="width: 100%; text-align: center"
                size="small"
                @click="logout"
              >
                退出登录
              </el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </slot>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { treeToMap } from '@/utils/tools'
import { localStore } from '@/utils/store'

export default {
  props: {
    menu: {
      type: Array,
      default: () => []
    },
    user: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    ...mapState({
      menuCollapse(state) {
        return state.menuCollapse
      }
    }),
    menuMap() {
      return treeToMap(this.menu)
    },
    matchedRoutes() {
      let matched = this.$route.matched.slice(1) || []
      matched = matched.filter((item) => item.name && !item.path.endsWith('/'))
      return matched.map((item, i) => {
        const currentMenu = this.menuMap[item.name] || {}
        return {
          name: currentMenu.key,
          label: currentMenu.name,
          enabled: currentMenu.hideChildrenInMenu && i !== matched.length - 1
        }
      })
    }
  },
  methods: {
    menuCollapseToggle() {
      this.$store.commit('menuCollapseUpdate', !this.menuCollapse)
    },
    logout() {
      const path = this.$route.path
      if (path.startsWith('/competitor')) {
        localStore.remove('competitor-token')
      }
      if (path.startsWith('/admin')) {
        localStore.remove('dashboard-token')
      }
      if (path.startsWith('/judge')) {
        localStore.remove('judge-token')
        localStore.remove('judge-id')
      }
      location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.com-header-view {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.com-header-view__left {
  display: flex;
  align-items: center;
}
.com-header-view__triggle {
  cursor: pointer;
  margin-right: 10px;
  font-size: 20px;
}
</style>
