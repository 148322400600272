<template>
  <el-dialog :visible.sync="dialogVisible" width="60%" destroy-on-close>
    <video
      v-if="dialogVisible"
      class="video"
      :src="video"
      controls
      autoplay
    ></video>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      video: ''
    }
  },
  methods: {
    open(video) {
      this.dialogVisible = true
      this.video = video
    },
    close() {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.video {
  width: 100%;
  max-height: 60vh;
}
</style>
