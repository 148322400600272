import RouterHolder from '@/components/router-holder'
import * as ConstantRouter from '@/constant/router'

const route = {
  path: 'banners',
  name: ConstantRouter.DASHBOARD_BANNERS,
  component: RouterHolder,
  redirect: '/admin/banners',
  children: [
    {
      path: '',
      component: () =>
        import(
          /* webpackChunkName: "dashboard-banners" */ '@/dashboard/banners'
        )
    },
    {
      path: 'add',
      name: ConstantRouter.DASHBOARD_BANNERS_ADD,
      component: () =>
        import(
          /* webpackChunkName: "dashboard-banners-add" */ '@/dashboard/banners/add'
        )
    },
    {
      path: 'update/:id',
      name: ConstantRouter.DASHBOARD_BANNERS_UPDATE,
      component: () =>
        import(
          /* webpackChunkName: "dashboard-banners-add" */ '@/dashboard/banners/add'
        )
    },
    {
      path: 'detail/:id',
      name: ConstantRouter.DASHBOARD_BANNERS_DETAIL,
      component: () =>
        import(
          /* webpackChunkName: "dashboard-banners-detail" */ '@/dashboard/banners/detail'
        )
    }
  ]
}

export default route
