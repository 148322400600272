const apis = [
  {
    // 系统用户分页列表
    fn: 'getPageList',
    method: 'POST',
    url: '/api/sysUser/getPageList',
    hideLoading: true
  },
  {
    // 添加系统用户
    fn: 'add',
    method: 'POST',
    url: '/api/sysUser/add',
    hideLoading: true
  },
  {
    // 修改系统用户
    fn: 'update',
    method: 'POST',
    url: '/api/sysUser/update',
    hideLoading: true
  },
  {
    // 删除系统用户
    fn: 'delete',
    method: 'POST',
    url: '/api/sysUser/delete',
    hideLoading: true
  },
  {
    // 系统用户详情
    fn: 'info',
    method: 'POST',
    url: '/api/sysUser/info',
    hideLoading: true
  },
  {
    // 管理员重置用户密码
    fn: 'info',
    method: 'POST',
    url: '/api/sysUser/resetPassword',
    hideLoading: true
  },
  {
    // 修改密码
    fn: 'info',
    method: 'POST',
    url: '/api/sysUser/updatePassword',
    hideLoading: true
  },
  {
    // 修改头像
    fn: 'info',
    method: 'POST',
    url: '/api/sysUser/uploadHead',
    hideLoading: true
  }
]

export default apis
