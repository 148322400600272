import RouterHolder from '@/components/router-holder'
import * as ConstantRouter from '@/constant/router'

const route = {
  path: 'popup',
  name: ConstantRouter.DASHBOARD_POPUP,
  component: RouterHolder,
  redirect: '/admin/popup',
  children: [
    {
      path: '',
      component: () =>
        import(
          /* webpackChunkName: "dashboard-popup" */ '@/dashboard/popup'
        )
    },
    {
      path: 'add',
      name: ConstantRouter.DASHBOARD_POPUP_ADD,
      component: () =>
        import(
          /* webpackChunkName: "dashboard-popup-add" */ '@/dashboard/popup/add'
        )
    },
    {
      path: 'update/:id',
      name: ConstantRouter.DASHBOARD_POPUP_UPDATE,
      component: () =>
        import(
          /* webpackChunkName: "dashboard-popup-add" */ '@/dashboard/popup/add'
        )
    },
    {
      path: 'detail/:id',
      name: ConstantRouter.DASHBOARD_POPUP_DETAIL,
      component: () =>
        import(
          /* webpackChunkName: "dashboard-popup-detail" */ '@/dashboard/popup/detail'
        )
    }
  ]
}

export default route
