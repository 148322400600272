const apis = [
  {
    // 作品分页列表
    fn: 'getPageList',
    method: 'POST',
    url: '/api/jsOpus/getPageList',
    hideLoading: true
  },
  {
    // 管理员作品分页列表
    fn: 'getPageListAdmin',
    method: 'POST',
    url: '/api/jsOpus/getPageListAdmin',
    hideLoading: true
  },
  {
    // 添加作品
    fn: 'add',
    method: 'POST',
    url: '/api/jsOpus/add',
    hideLoading: true
  },
  {
    // 修改作品
    fn: 'update',
    method: 'POST',
    url: '/api/jsOpus/update',
    hideLoading: true
  },
  {
    // 删除作品
    fn: 'delete',
    method: 'POST',
    url: '/api/jsOpus/delete',
    hideLoading: true
  },
  {
    // 作品详情
    fn: 'info',
    method: 'GET',
    url: '/api/jsOpus/info',
    hideLoading: true
  },
  {
    // 打分进度(复审)
    fn: 'getOpusScoreCount',
    method: 'POST',
    url: '/api/jsOpus/getOpusScoreCount',
    hideLoading: true
  },
  {
    // 打分进度(初审)
    fn: 'getOpusScoreCountFirst',
    method: 'POST',
    url: '/api/jsOpus/getOpusScoreCountFirst',
    hideLoading: true
  },
  {
    // 获取未打分记录(复审)
    fn: 'getOpusUnScore',
    method: 'POST',
    url: '/api/jsOpus/getOpusUnScore',
    hideLoading: true
  },
  {
    // 获取未打分记录(初审)
    fn: 'getOpusUnScoreFirst',
    method: 'POST',
    url: '/api/jsOpus/getOpusUnScoreFirst',
    hideLoading: true
  },
  {
    // 导出作品列表
    fn: 'exportOpusList',
    method: 'POST',
    url: '/api/jsOpus/exportOpusList',
    hideLoading: true,
    blob: true,
    responseType: 'arraybuffer'
  },
  {
    // 导出投票结果
    fn: 'exportOpusScore',
    method: 'POST',
    url: '/api/jsOpus/exportOpusScore',
    hideLoading: true,
    blob: true,
    responseType: 'arraybuffer'
  }
]

export default apis
