const apis = [
  {
    // 系统登录
    fn: 'login',
    method: 'POST',
    url: '/api/login',
    hideLoading: true
  },
  {
    // 系统退出
    fn: 'add',
    method: 'POST',
    url: '/api/logout',
    hideLoading: true
  },
  {
    // 根据token获取系统登录用户信息
    fn: 'getSysUserInfo',
    method: 'GET',
    url: '/api/getSysUserInfo',
    hideLoading: true
  }
]

export default apis
