import { isView } from '@/utils/tools'
import { lang } from '@/config/env'

const apis = [
  {
    // 弹窗配置分页列表
    fn: 'getPageList',
    method: 'POST',
    url: '/api/jsTanchuang/getPageList',
    hideLoading: true
  },
  {
    // 添加弹窗配置
    fn: 'add',
    method: 'POST',
    url: '/api/jsTanchuang/add',
    hideLoading: true
  },
  {
    // 修改弹窗配置
    fn: 'update',
    method: 'POST',
    url: '/api/jsTanchuang/update',
    hideLoading: true
  },
  {
    // 删除弹窗配置
    fn: 'delete',
    method: 'POST',
    url: '/api/jsTanchuang/delete',
    hideLoading: true
  },
  {
    // 弹窗配置详情
    fn: 'info',
    method: 'GET',
    url: '/api/jsTanchuang/info',
    hideLoading: true
  },
  {
    // 获取当前弹窗配置详情
    fn: 'infoByJs',
    method: 'GET',
    url: '/api/jsTanchuang/infoByJs',
    hideLoading: true,
    processLang(res) {
      if (!isView()) return res
      if (lang === 'cn') return res
      if (!res) return res
      return {
        ...res,
        title: res.titleEn
      }
    }
  }
]

export default apis
