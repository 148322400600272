import { isView } from '@/utils/tools'
import { lang } from '@/config/env'

const apis = [
  {
    // 文章分页列表
    fn: 'getPageList',
    method: 'POST',
    url: '/api/gwCase/getPageList',
    hideLoading: true,
    processLang(res) {
      if (!isView()) return res
      if (lang === 'cn') return res
      if (!res) return res
      return {
        ...res,
        records: res.records.map(({ titleEn, ...data }) => ({
          ...data,
          title: titleEn
        }))
      }
    }
  },
  {
    // 添加文章
    fn: 'add',
    method: 'POST',
    url: '/api/gwCase/add',
    hideLoading: true
  },
  {
    // 修改文章
    fn: 'update',
    method: 'POST',
    url: '/api/gwCase/update',
    hideLoading: true
  },
  {
    // 删除文章
    fn: 'delete',
    method: 'POST',
    url: '/api/gwCase/delete',
    hideLoading: true
  },
  {
    // 文章详情
    fn: 'info',
    method: 'GET',
    url: '/api/gwCase/info',
    hideLoading: true,
  }
]

export default apis
