export const HOME = 'home'

/**
 * view
 */
export const VIEW = 'view'
export const INDEX = 'index'
export const EVENT = 'event'
export const GUIDE = 'guide'
export const NEWS = 'news'

/**
 * competitor
 */
export const COMPETITOR = 'competitor'

export const COMPETITOR_LOGIN = 'competitor-login'
export const COMPETITOR_REGISTER = 'competitor-register'

export const COMPETITOR_WORKS = 'competitor-works'
export const COMPETITOR_WORKS_LIST = 'competitor-works-list'
export const COMPETITOR_WORKS_ADD = 'competitor-works-add'
export const COMPETITOR_WORKS_UPDATE = 'competitor-works-update'
export const COMPETITOR_WORKS_DETAIL = 'competitor-works-detail'

export const COMPETITOR_MEMBERS = 'competitor-members'
export const COMPETITOR_MEMBERS_LIST = 'competitor-members-list'
export const COMPETITOR_MEMBERS_ADD = 'competitor-members-add'
export const COMPETITOR_MEMBERS_UPDATE = 'competitor-members-update'
export const COMPETITOR_MEMBERS_DETAIL = 'competitor-members-detail'

export const COMPETITOR_PASSWORD_RESET = 'competitor-password-reset'

/**
 * judge
 */
export const JUDGE = 'judge'

export const JUDGE_LOGIN = 'judge-login'

export const JUDGE_REVIEW = 'judge-review'
export const JUDGE_REVIEW_LIST = 'judge-review-list'
export const JUDGE_REVIEW_ADD = 'judge-review-add'
export const JUDGE_REVIEW_DETAIL = 'judge-review-detail'

/**
 * dashboard
 */
export const DASHBOARD = 'dashboard'

export const DASHBOARD_LOGIN = 'dashboard-login'

export const DASHBOARD_BANNERS = 'dashboard-banners'
export const DASHBOARD_BANNERS_ADD = 'dashboard-banners-add'
export const DASHBOARD_BANNERS_UPDATE = 'dashboard-banners-update'
export const DASHBOARD_BANNERS_DETAIL = 'dashboard-banners-detail'

export const DASHBOARD_NEWS = 'dashboard-news'
export const DASHBOARD_NEWS_ADD = 'dashboard-news-add'
export const DASHBOARD_NEWS_UPDATE = 'dashboard-news-update'
export const DASHBOARD_NEWS_DETAIL = 'dashboard-news-detail'

export const DASHBOARD_COMPETITION = 'dashboard-competition'
export const DASHBOARD_COMPETITION_ADD = 'dashboard-competition-add'
export const DASHBOARD_COMPETITION_UPDATE = 'dashboard-competition-update'
export const DASHBOARD_COMPETITION_TRACK = 'dashboard-competition-track'
export const DASHBOARD_COMPETITION_SCHEDULE = 'dashboard-competition-schedule'
export const DASHBOARD_COMPETITION_GUIDE = 'dashboard-competition-guide'
export const DASHBOARD_COMPETITION_DETAIL = 'dashboard-competition-detail'

export const DASHBOARD_WORKS = 'dashboard-works'
export const DASHBOARD_WORKS_ADD = 'dashboard-works-add'
export const DASHBOARD_WORKS_DETAIL = 'dashboard-works-detail'

export const DASHBOARD_EXPERTS = 'dashboard-experts'
export const DASHBOARD_EXPERTS_ADD = 'dashboard-experts-add'
export const DASHBOARD_EXPERTS_UPDATE = 'dashboard-experts-update'
export const DASHBOARD_EXPERTS_DETAIL = 'dashboard-experts-detail'

export const DASHBOARD_POPUP = 'dashboard-popup'
export const DASHBOARD_POPUP_ADD = 'dashboard-popup-add'
export const DASHBOARD_POPUP_UPDATE = 'dashboard-popup-update'
export const DASHBOARD_POPUP_DETAIL = 'dashboard-popup-detail'

export const DASHBOARD_MINI_BANNERS = 'dashboard-mini-banners'
export const DASHBOARD_MINI_BANNERS_ADD = 'dashboard-mini-banners-add'
export const DASHBOARD_MINI_BANNERS_UPDATE = 'dashboard-mini-banners-update'
export const DASHBOARD_MINI_BANNERS_DETAIL = 'dashboard-mini-banners-detail'

export const DASHBOARD_MINI_ARTICLE = 'dashboard-mini-article'
export const DASHBOARD_MINI_ARTICLE_ADD = 'dashboard-mini-article-add'
export const DASHBOARD_MINI_ARTICLE_UPDATE = 'dashboard-mini-article-update'
export const DASHBOARD_MINI_ARTICLE_DETAIL = 'dashboard-mini-article-detail'

/**
 * common
 */
export const COMMON_PASSWORD_RESET = 'common-password-reset'
