import RouterHolder from '@/components/router-holder'
import * as ConstantRouter from '@/constant/router'

const route = {
  path: 'review',
  name: ConstantRouter.JUDGE_REVIEW,
  component: RouterHolder,
  redirect: {
    name: ConstantRouter.JUDGE_REVIEW_LIST
  },
  children: [
    {
      path: '',
      name: ConstantRouter.JUDGE_REVIEW_LIST,
      component: () =>
        import(/* webpackChunkName: "judge-review" */ '@/judge/review')
    },
    {
      path: 'detail/:id',
      name: ConstantRouter.JUDGE_REVIEW_DETAIL,
      component: () =>
        import(
          /* webpackChunkName: "judge-review-detail" */ '@/judge/review/detail'
        )
    }
  ]
}

export default route
