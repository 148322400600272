import { isView } from '@/utils/tools'
import { lang } from '@/config/env'

const apis = [
  {
    // 轮播图分页列表
    fn: 'getPageList',
    method: 'POST',
    url: '/api/jsBanner/getPageList',
    hideLoading: true,
    processLang(res) {
      if (!isView()) return res
      if (lang === 'cn') return res
      if (!res) return res
      return {
        ...res,
        records: res.records.map(({ imgUrlEn, ...data }) => ({
          ...data,
          imgUrl: imgUrlEn
        }))
      }
    }
  },
  {
    // 添加轮播图
    fn: 'add',
    method: 'POST',
    url: '/api/jsBanner/add',
    hideLoading: true
  },
  {
    // 修改轮播图
    fn: 'update',
    method: 'POST',
    url: '/api/jsBanner/update',
    hideLoading: true
  },
  {
    // 删除轮播图
    fn: 'delete',
    method: 'POST',
    url: '/api/jsBanner/delete',
    hideLoading: true
  },
  {
    // 轮播图详情
    fn: 'info',
    method: 'GET',
    url: '/api/jsBanner/info',
    hideLoading: true
  }
]

export default apis
