import RouterHolder from '@/components/router-holder'
import * as ConstantRouter from '@/constant/router'

const route = {
  path: 'works',
  name: ConstantRouter.COMPETITOR_WORKS,
  component: RouterHolder,
  redirect: {
    name: ConstantRouter.COMPETITOR_WORKS_LIST
  },
  children: [
    {
      path: '',
      name: ConstantRouter.COMPETITOR_WORKS_LIST,
      component: () =>
        import(/* webpackChunkName: "competitor-works" */ '@/competitor/works')
    },
    {
      path: 'add',
      name: ConstantRouter.COMPETITOR_WORKS_ADD,
      component: () =>
        import(
          /* webpackChunkName: "competitor-works-add" */ '@/competitor/works/add'
        )
    },
    {
      path: 'update/:id',
      name: ConstantRouter.COMPETITOR_WORKS_UPDATE,
      component: () =>
        import(
          /* webpackChunkName: "competitor-works-add" */ '@/competitor/works/add'
        )
    },
    {
      path: 'detail/:id',
      name: ConstantRouter.COMPETITOR_WORKS_DETAIL,
      component: () =>
        import(
          /* webpackChunkName: "competitor-works-detail" */ '@/competitor/works/detail'
        )
    }
  ]
}

export default route
