import { isView } from '@/utils/tools'
import { lang } from '@/config/env'

const apis = [
  {
    // 参赛指南分页列表
    fn: 'getPageList',
    method: 'POST',
    url: '/api/jsGuide/getPageList',
    hideLoading: true
  },
  {
    // 添加参赛指南
    fn: 'add',
    method: 'POST',
    url: '/api/jsGuide/add',
    hideLoading: true
  },
  {
    // 修改参赛指南
    fn: 'update',
    method: 'POST',
    url: '/api/jsGuide/update',
    hideLoading: true
  },
  {
    // 删除参赛指南
    fn: 'delete',
    method: 'POST',
    url: '/api/jsGuide/delete',
    hideLoading: true
  },
  {
    // 参赛指南详情
    fn: 'info',
    method: 'GET',
    url: '/api/jsGuide/info',
    hideLoading: true,
    processLang(res) {
      if (!isView()) return res
      if (lang === 'cn') return res
      if (!res) return res
      return {
        ...res,
        context: res.contextEn
      }
    }
  }
]

export default apis
