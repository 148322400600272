<script>
import queryString from 'query-string'

export default {
  name: 'tree-menu',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    active: String,
    menuClick: Function
  },
  functional: true,
  render(_, { props }) {
    const { data, menuClick } = props
    return data.map((item) => {
      const { key, name, icon, hideChildrenInMenu, hide, children, query } =
        item
      const uniKey = query ? `${key}?${queryString.stringify(query)}` : key
      if (hide) {
        return null
      }
      if (!hideChildrenInMenu && children?.length) {
        return (
          <el-submenu key={uniKey} index={uniKey}>
            <template slot="title">
              {icon ? <i class={icon}></i> : ''}
              <span slot="title">{name}</span>
            </template>
            <tree-menu data={children} menu-click={menuClick}></tree-menu>
          </el-submenu>
        )
      }

      return (
        <el-menu-item
          class="menu-item"
          key={uniKey}
          index={uniKey}
          onClick={() => menuClick(item)}
        >
          {icon ? <i class={icon}></i> : ''}
          <span slot="title">{name}</span>
        </el-menu-item>
      )
    })
  }
}
</script>
